// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(SelfLearningHierarchyQuery)/BasicConditions";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(SelfLearningHierarchyQuery)/SingleTagCondition";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(SelfLearningHierarchyQuery)/ContentIdCondition";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        value: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.value) === null || _a === void 0 ? void 0 : _a.field) {
        case "basicConditions": {
            result.basicConditions = encodeJson_1(value.value.value);
            break;
        }
        case "singleTagCondition": {
            result.singleTagCondition = encodeJson_2(value.value.value);
            break;
        }
        case "contentIdCondition": {
            result.contentIdCondition = encodeJson_3(value.value.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.basicConditions !== undefined)
        result.value = { field: "basicConditions", value: decodeJson_1(value.basicConditions) };
    if (value.singleTagCondition !== undefined)
        result.value = { field: "singleTagCondition", value: decodeJson_2(value.singleTagCondition) };
    if (value.contentIdCondition !== undefined)
        result.value = { field: "contentIdCondition", value: decodeJson_3(value.contentIdCondition) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.value) === null || _a === void 0 ? void 0 : _a.field) {
        case "basicConditions": {
            const tsValue = value.value.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "singleTagCondition": {
            const tsValue = value.value.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "contentIdCondition": {
            const tsValue = value.value.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    value: new Set([1, 2, 3]),
};
const oneofFieldNamesMap = {
    value: new Map([
        [1, "basicConditions"],
        [2, "singleTagCondition"],
        [3, "contentIdCondition"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.value;
        const oneofFieldNames = oneofFieldNamesMap.value;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.value = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
