// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(ProficiencyDeclaration)/TagsForToeicTagProficiency";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../../../google/protobuf/Empty";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        value: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.value) === null || _a === void 0 ? void 0 : _a.field) {
        case "tagsForTagProficiency": {
            result.tagsForTagProficiency = encodeJson_1(value.value.value);
            break;
        }
        case "useSelfLearningHierarchyProficiency": {
            result.useSelfLearningHierarchyProficiency = encodeJson_2(value.value.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.tagsForTagProficiency !== undefined)
        result.value = { field: "tagsForTagProficiency", value: decodeJson_1(value.tagsForTagProficiency) };
    if (value.useSelfLearningHierarchyProficiency !== undefined)
        result.value = { field: "useSelfLearningHierarchyProficiency", value: decodeJson_2(value.useSelfLearningHierarchyProficiency) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.value) === null || _a === void 0 ? void 0 : _a.field) {
        case "tagsForTagProficiency": {
            const tsValue = value.value.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "useSelfLearningHierarchyProficiency": {
            const tsValue = value.value.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    value: new Set([1, 2]),
};
const oneofFieldNamesMap = {
    value: new Map([
        [1, "tagsForTagProficiency"],
        [2, "useSelfLearningHierarchyProficiency"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.value;
        const oneofFieldNames = oneofFieldNamesMap.value;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.value = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
